import React from "react"
import RegisterButton from "./register-button"
import { categoryTheme } from "./programs.theme"

const ProgramDetail = ({ program }) => {
  const {
    frontmatter: {
      title,
      registrationLink,
      details,
      schedule,
      category,
      paused,
    },
    html,
  } = program

  const theme = categoryTheme[category]
  const Register = () => (
    <RegisterButton link={registrationLink} theme={theme} paused={paused} />
  )
  return (
    <div
      className={`mb-10 border md:border-2 p-5 md:p-10 rounded-lg flex flex-col md:flex-row ${theme.background} ${theme.border}`}
    >
      <div className="w-full md:w-1/3 md:pr-10">
        <h4>{title}</h4>
        <div className="hidden md:block md:mt-5">
          <Register />
        </div>
        <div className="italic mt-2 mb-4 md:mt-7 text-xs md:text-sm">
          {details}
        </div>
        <div className="italic mt-2 mb-4 md:mt-5 text-xs md:text-sm">
          <div className="not-italic font-bold md:mb-1">SCHEDULE</div>
          {schedule || "COMING SOON"}
        </div>
      </div>
      <div
        className="w-full md:w-2/3"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="md:hidden mt-6">
        <Register />
      </div>
    </div>
  )
}

export default ProgramDetail
