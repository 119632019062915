import React from "react"
import { graphql } from "gatsby"

import BaseLayout from "../components/layout/base-layout"
import ProgramList from "../components/program-list/program-list"
import { ContentWrapper, Hero } from "@wonder-horse/shared"
import FlyerGrid from "../components/flyer-grid/flyer-grid"

const Programs = ({ data }) => {
  const {
    allFile: { totalCount, edges },
    file: {
      childImageSharp: { gatsbyImageData },
    },
  } = data
  const comingSoon = true

  return (
    <BaseLayout title="Programs">
      <Hero img={gatsbyImageData} />
      <ContentWrapper>
        <h1>Programs</h1>
        {comingSoon ? (
          <div className="text-center mb-5 md:mb-0">
            <p className="text-sm">
              For additional information contact
              <a
                className="block text-sm"
                href="mailto:info@wonderhorseranch.org"
              >
                info@wonderhorseranch.org
              </a>
            </p>
          </div>
        ) : (
          <p className="italic">{`${totalCount} programs currently available`}</p>
        )}
        <FlyerGrid />
        <h3 className="mt-5 md:mt-10">Recurring Programs</h3>
        <ProgramList programs={edges} />
      </ContentWrapper>
    </BaseLayout>
  )
}

export const query = graphql`
  query ProgramsQuery {
    allFile(
      filter: {
        relativeDirectory: { eq: "programs" }
        childMarkdownRemark: { frontmatter: { disabled: { eq: false } } }
      }
      sort: { fields: name, order: ASC }
    ) {
      totalCount
      edges {
        node {
          name
          childMarkdownRemark {
            html
            frontmatter {
              title
              registrationLink
              details
              schedule
              category
              paused
              disabled
            }
          }
        }
      }
    }
    file(relativePath: { eq: "programs_hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default Programs
