import React from "react"
import ProgramDetail from "./program-detail"

const ProgramList = ({ programs }) => {
  const programList = programs.map(({ node: { childMarkdownRemark } }, i) => (
    <ProgramDetail key={`program-${i}`} program={childMarkdownRemark} />
  ))
  return (
    <div className="my-3 md:my-5 md:my-10 w-full md:w-2/3">{programList}</div>
  )
}

export default ProgramList
